import React from "react"
import CardPartner from "../components/cardPartner"
import IntegrationCardsContainer from "../components/integrationCardsContainer"
import BackgroundShape from "../components/backgroundShape"
import Navbar from "../components/navbar"
import Button from "../components/button"
import Header from "../components/header"
import Container from "../components/container"
import Layout from "../components/layout"
import SectionLayout from "../components/sectionLayout"
import SubHeader from "../components/subHeader"
import Head from "../components/head"
import hero from "../assets/icons/hero.svg"
import { graphql } from "gatsby"



// export const query = graphql`
//   	query{
// 		allContentfulPartner {
// 			edges {
// 				node {
// 					name
// 					website
// 					slug
// 					description {
// 						description
// 					}
// 					partnerCategory {
// 						title
// 					}
// 				}
// 		  	}
// 		}
// 		allContentfulPartnerCategory {
// 			edges {
// 				node {
// 					title
// 				}
// 			}
// 		}
// 	}
// `
export const query = graphql`
query {
	allContentfulPlatform {
	  edges {
		node {
		  slug
		  title
		  summary
		  plans
		  orderSync
		  inventorySync
		  beta
		  solutionPartner
		  integrationCategory {
			title
		  }
		}
	  }
	}
  allContentfulPlatformCategory {
	edges {
	  node {
		title
	  }
	}
}
  }

`

const filter = (category) => {
	document.querySelectorAll('.filterTarget').forEach((target) => {
		if ( category == 'All') {
			target.style.display='block'
		} else if (target.dataset.category === category) {
			target.style.display='block'
		} else {
			target.style.display='none'
		}
	})
  }

const Partners = (props) => {
	return (
		<Layout navbar="transparent">
			<Head title="An interconnected ecosystem of platforms and solutions for business growth" description="We believe in the power of collaboration and integrate with a wide range of different platforms and solutions for scaling marketplaces."/>
				<BackgroundShape
					style={{ shape: "clip", color: "bg-partnersC-lg", particles: false }}
				/>
				<Container style={{ classes: "banner-container container" }}>
					<div className="d-flex justify-content-between align-items-center">
						<div className="header w-50">
							<h1 className="header__banner-title">Partner With Onport</h1>
							<p className="header__banner-subtitle">
								Here at Onport, we understand that in business, no company is an island. That is why we believe in the strength of collaboration when it comes to fueling innovation and helping to sculpt the marketplaces of the future.							</p>
							<a href="/contacts/partners" className="btn-home alt">
								Become a partner
							</a>
						</div>
						<div className="w-50 desktop-only text-center">
						<img src='http://onport.com/images/partners-header.svg' style={{height:'500px'}} data-sal='slide-left' data-sal-duration='600'/>
						</div>
					</div>
				</Container>
				<Container style={{ classes: "container pb-5" }}>
					<div className="d-flex justify-content-between align-items-center pb-4">
						<SubHeader
							content={{
								title: "Establish Your Marketplace Solution Within Composable Commerce Infrastructure",
								text:
								"Together with Onport, you can ensure that your solution becomes an integrated component into the ever-growing tapestry of modern commerce. Unveil new opportunities for growth and enhance the value proposition to your customers.</br>The marketplace eco-space is full of intriguing and promising collaborative opportunities which offer true value to both parties and provide tangible results. Join our partnership program and see the results for yourself today.",
							}}
							style={{ color: "mt-5" }}
							/>
						<div className="w-25 desktop-only position-relative">
							<img
							src='https://assets.jetti.io/assets/illustrations/merge.svg'
							alt="home"
							data-sal="slide-left"
							data-sal-delay="300"
							data-sal-easing="ease"
							data-sal-duration="600"
							/>
						</div>
					</div>
				</Container>

			<Container style={{ classes: "container-fluid bg-partnersC-md pb-5" }}>
					<Container style={{ classes: "container pb-5" }}>
						<SubHeader
							content={{
								title: "Why You Should Partner With Onport",
								text: "Onport is well integrated into the marketplace eco-space, having formed robust relationships with many different platforms and solutions, all working together towards a common goal - to elevate the marketplace model to the highest possible level. Why not join our community today?",
							}}
							style={{ color: "text-white" }}
							/>
							<div className="row justify-content-around align-items-center">
								<div className="col-12 col-md-4">
									<img style={{ height: "64px", width: "64px", margin: "2rem auto", display: "block", }} src="https://taact-brand-assets.netlify.app/assets/icons/jetti/rocket-reverse.svg" data-sal="fade" data-sal-easing="ease" data-sal-duration="900" />
									<h2 style={{ fontSize: "1rem" }} className="text-white text-center" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="600" >GROWTH</h2>
									<p className="text-white text-center" style={{fontWeight: '300', fontSize:'.9rem'}}>Grow your business and increase the size of your product audience by aligning your solution with a leading online marketplace platform</p>
								</div>
								<div className="col-12 col-md-4">
									<img style={{ height: "64px", width: "64px", margin: "2rem auto", display: "block", }} src="https://taact-brand-assets.netlify.app/assets/icons/jetti/size-alt-reverse.svg" data-sal="fade" data-sal-easing="ease" data-sal-duration="900" />
									<h2 style={{ fontSize: "1rem" }} className="text-white text-center" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="600" >FLEXIBILITY</h2>
									<p className="text-white text-center" style={{fontWeight: '300', fontSize:'.9rem'}}>A flexible platform built on modern technology that’s easy to learn and work with.</p>
								</div>
								<div className="col-12 col-md-4">
									<img style={{ height: "64px", width: "64px", margin: "2rem auto", display: "block", }} src="https://taact-brand-assets.netlify.app/assets/icons/jetti/brand-reverse.svg" data-sal="fade" data-sal-easing="ease" data-sal-duration="900" />
									<h2 style={{ fontSize: "1rem" }} className="text-white text-center" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="600" >OPPORTUNITY</h2>
									<p className="text-white text-center" style={{fontWeight: '300', fontSize:'.9rem'}}>Gain high-level opportunities by exploring a thriving and dynamic eco-space</p>
								</div>
							</div>
					</Container>
			</Container>
			<div id='filter'>
			<SubHeader
					content={{
						title: "Partners",
						text: "Onport integrates seamlessly with the world's biggest ecommerce platforms, marketplaces, shipping couriers and accounting platforms.",
					}}
					style={{ color: "text-center mx-auto pb-0" }}
				/>
                      <div className="integration-category-btn features-grid features-grid__center features-grid__8-col">
                      <Button style={{ href:`/partners`, text: "All", shape: "btn-integration", active:"btn-integration btn-integration__active"}}/>
                      { props.data.allContentfulPlatformCategory.edges.sort((a, b) => b.node.title.localeCompare(a.node.title)).map((edge) => {
                            return(
                              <Button style={{ href:`/partners/${edge.node.title.toLowerCase()}#filter`, text: edge.node.title, shape: "btn-integration"}}/>
                              )
                            }
                        )
                      }
                    </div>
                <IntegrationCardsContainer>
                  {props.data.allContentfulPlatform.edges.sort((a, b) => a.node.title.localeCompare(b.node.title)).map((edge) => {
                      return(
                        <CardPartner content={{
                          title: edge.node.title,
                          slug: edge.node.slug,
                          plans: edge.node.plans,
                          summary: edge.node.summary,
                          category: edge.node.integrationCategory.title.toLowerCase(),
						  solution: edge.node.solutionPartner,
                          image: require(`../assets/icons/platform/round/${edge.node.slug}.svg`)}}/>
                      )})
                  }
                </IntegrationCardsContainer>
			{/* <Container style={{classes: "container spacing-top" }}>
				<SubHeader
					content={{
						title: "Partners",
						text: "Onport integrates seamlessly with the world's biggest ecommerce platforms, marketplaces, shipping couriers and accounting platforms.",
					}}
					style={{ color: "text-center mx-auto" }}
				/>
				<div className="my-5 w-75 mx-auto features-grid features-grid__center features-grid__7-col">
					<span onClick={() => filter('All')} className="btn-integration">All</span>
					  { props.data.allContentfulPartnerCategory.edges.map((edge) => {
                            return(
                              <span onClick={() => filter(edge.node.title)} className="btn-integration text-center">{edge.node.title}</span>
                              )
                            }
                        )
                      }
                    </div>
				<div className="features-grid features-grid__center features-grid__3-col">
					{ props.data.allContentfulPartner.edges.map((edge) => {
						return(
							<CardPartner content={{
								title: edge.node.name,
								slug: edge.node.website,
								plans: '',
								summary: edge.node.description.description,
								category: edge.node.partnerCategory.title ,
								image: require(`../assets/icons/platform/round/${edge.node.slug}.svg`)}} />
								)})
							}
				</div>
			</Container> */}
			<Container style={{classes: "container spacing-top text-center mb-5" }}>
				<SubHeader
							content={{
								title: "Become an Onport Partner",
								text: "Onport integrates seamlessly with the world's biggest ecommerce platforms, marketplaces, shipping couriers and accounting platforms.",
							}}
							style={{ color: "mx-auto" }}
				/>
							<a href="/contacts/partners" className="btn-home alt text-center">Become a partner</a>						
				</Container>
				</div>
		</Layout>
    )
}


export default Partners
